'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.users.factory:User

 # @description

###
angular
  .module 'mundoAdmin.users'
  .factory 'TenantUserManager', [
    '$rootScope'
    '$log'
    'MundoDefaultManager'
    'UserManager'
    'UserRoleManager'
    'TenantManager'
    'UserContextGroupManager'
    'UserContextManager'
    'ConfirmBox'
    '$state'
    '$translate'
    'timezones'
    '$q',
    'Restangular'
    'mundoConfiguration'
    'OAuth'
    'OAuthToken'
    '$window'
    '$cookies'
    (
      $rootScope
      $log
      MundoDefaultManager
      UserManager
      UserRoleManager
      TenantManager
      UserContextGroupManager
      UserContextManager
      ConfirmBox
      $state
      $translate
      timezones
      $q
      Restangular
      mundoConfiguration
      OAuth
      OAuthToken
      $window
      $cookies
    ) ->
      UserBase =  new MundoDefaultManager()

      UserBase.setUrl('users')
      UserBase.setNewObject(['username', 'email', 'locale', 'timezone'])
      UserBase.setUpdateObject(['username', 'email', 'locale', 'timezone', 'plainPassword', 'enabled'])
      UserBase.setObject('userContext', ['user', 'roles', 'userContextGroups', 'tenant', 'disabled'])
      UserBase.setSearchFields(['user.username', 'user.email', 'user'])
      UserBase.impersonateUserPermission = 'user impersonation'

      UserBase.defaultQuery.sort = 'user.username'

      UserBase.delete = (id) ->
        @transport.one('user_contexts', id).remove()

      UserBase.new = (data) ->
        #check if user already exists but not in this context
        userExistsInOtherContext(data.email)
          .then(
            (result) =>
              roles =  data.roles
              data.roles = undefined
              groups = data.userContextGroups
              data.userContextGroups = undefined
              tenants = @extractIds(data.tenants)
              tenants = if tenants? then tenants else []
              tenants.push $rootScope.user.activeUserContext.tenant.id
              data.tenants = undefined
              #link the existing user to the current tennant
              if result?
                title = "Link this user to the current tennant?"
                message = "The email address '#{data.email}' is already in use by the user '#{result.username}'.\n"
                message += "Are you sure you want to link this user to the current tennant"
                message += " (#{$rootScope.user.activeUserContext.tenant.label})?"
                ConfirmBox.showConfirmDialog(title, message)
                  .then(=>
                    userContextData = {}
                    userContextData.user = result.id
                    userContextData.tenant = $rootScope.user.activeUserContext.tenant.id
                    userContextData.roles = @extractIds(roles)
                    userContextData.userContextGroups = @extractIds(groups)
                    @transport.all('user_contexts').post(_.pick(userContextData, @getObject('userContext')))
                      .then(-> $state.reload())
                  )
              #else just proceed creating the user
              else
                @transport.all('users').post(_.pick(data, @getNewObject())).then (userObject) =>
                  for tenant in tenants
                    userContextData = {}
                    userContextData.user = userObject.id
                    userContextData.tenant = tenant
                    if tenant == $rootScope.user.activeUserContext.tenant.id
                      userContextData.roles = @extractIds(roles)
                      userContextData.userContextGroups = @extractIds(groups)
                    @transport.all('user_contexts').post(_.pick(userContextData, @getObject('userContext')))
          )

      UserBase.submit = (data, entity) ->
        data = angular.copy data
        @setUrl('users')
        if entity
          userContextData = {}
          userContextData.disabled = !data.enabled

          roles =  data.roles
          data.enabled = true
          data.roles = undefined
          groups = data.userContextGroups
          data.userContextGroups = undefined
          @update(entity.user.id, data)
          userContextData.user = entity.user.id
          userContextData.roles = @extractIds(roles)
          userContextData.userContextGroups = @extractIds(groups)
          @transport.all('user_contexts').one(entity.id).customPUT(_.pick(userContextData, @getObject('userContext')))
        else
          @new(data)

      UserBase.getNewForm = ->
        [
          key: 'username'
          name: 'username'
          type: 'input'
          templateOptions:
            label: 'Username'
            placeholder: 'johndoe'
            required: true
            description: 'Descriptive text'
        ,
          key: 'email'
          name: 'email'
          type: 'input'
          templateOptions:
            type: 'email'
            label: 'Email address'
            placeholder: 'Enter email'
            required: true
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          templateOptions:
            label: 'User Groups'
            placeholder: 'User Groups'
            multiple: true,
            autocompleteLabel : 'add  or remove a user group'
            labelProp: "label"
            valueProp: "id"
            options: UserContextGroupManager.getFullList({'sort' : 'label'}).$object
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: UserRoleManager.getFullList({'sort' : 'label,ASC'}).$object
        # ,
        #   key: 'tenants'
        #   name: 'tenants'
        #   type: 'modelchips'
        #   templateOptions:
        #     label: 'Also add to child tenants'
        #     placeholder: 'tenants'
        #     multiple: true,
        #     labelProp: "label"
        #     autocompleteLabel : 'add  or remove a child tenant'
        #     valueProp: "id"
        #     options: TenantManager.getFullList()
        #       .then((tenants) -> return _.filter tenants ,(t) -> t.id != $rootScope.user.activeUserContext.tenant.id)
        ,
          key: 'locale'
          name: 'locale'
          type: 'select'
          defaultValue: $rootScope.user.locale,
          templateOptions:
            label: $translate.instant 'app.language'
            placeholder: $translate.instant 'app.language'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: [
                {id: 'nl', label: 'Nederlands'},
                {id: 'en', label: 'English'},
                {id: 'fr', label: 'Français'},
            ]
        ,
          key: 'timezone'
          name: 'timezone'
          type: 'select'
          defaultValue: $rootScope.user.timezone,
          templateOptions:
            label: $translate.instant 'app.timezone'
            placeholder: $translate.instant 'app.timezone'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: ({id: k, label: v} for v, k of timezones)
        # ,
        #   key: 'enabled'
        #   name: 'enabled'
        #   type: 'checkbox'
        #   templateOptions:
        #     label: 'enabled'
        ]

      UserBase.getEditForm = (data)->
        data.enabled = !data.disabled

        [
          key: 'username'
          name: 'username'
          type: 'input'
          defaultValue: data.user.username,
          templateOptions:
            label: 'Username'
            placeholder: 'johndoe'
            required: true
            description: 'Descriptive text'
        ,
          key: 'email'
          name: 'email'
          type: 'input'
          defaultValue: data.user.email,
          templateOptions:
            type: 'email'
            label: 'Email address'
            placeholder: 'Enter email'
            required: true
        ,
          key: 'plainPassword'
          name: 'plainPassword'
          type: 'input'
          defaultValue: data.user.plainPassword,
          templateOptions:
            type: 'password'
            label: 'Password'
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          defaultValue: data.userContextGroups
          templateOptions:
            label: 'User Groups'
            placeholder: 'User Groups'
            multiple: true,
            autocompleteLabel : 'add  or remove a user group'
            labelProp: "label"
            valueProp: "id"
            options: do (data) ->
              exclude = []
              groups = []
              for group in data.userContextGroups then exclude.push group.id
              UserContextGroupManager.getFullList({'sort' : 'label'}).then (data) ->
                for group in data
                  if group.id not in exclude then groups.push group
              groups
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          defaultValue: data.roles
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: do (data) ->
              exclude = []
              roles = []
              for role in data.roles then exclude.push role.id
              UserRoleManager.getFullList({'sort' : 'label,ASC'}).then (data) ->
                for role in data
                  if role.id not in exclude then roles.push role
              roles
        ,
          key: 'locale'
          name: 'locale'
          type: 'select'
          defaultValue: data.user.locale,
          templateOptions:
            label: $translate.instant 'app.language'
            placeholder: $translate.instant 'app.language'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: [
                {id: 'nl', label: 'Nederlands'},
                {id: 'en', label: 'English'},
                {id: 'fr', label: 'Français'},
            ]
        ,
          key: 'timezone'
          name: 'timezone'
          type: 'select'
          defaultValue: data.user.timezone
          templateOptions:
            label: $translate.instant 'app.timezone'
            placeholder: $translate.instant 'app.timezone'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: ({id: k, label: v} for v, k of timezones)
        # ,
        #   key: 'enabled'
        #   name: 'enabled'
        #   type: 'checkbox'
        #   defaultValue: data.user.enabled,
        #   templateOptions:
        #     label: 'enabled'
        ,
          key: 'enabled'
          name: 'enabled'
          type: 'checkbox'
          defaultValue: data.enabled,
          templateOptions:
            label: 'enabled'
        ]

      UserBase.getListSchema = ->
        [
          key: 'user.username'
          title: 'datatable.label.name'
          sort: 'user.username'
        ,
          key: 'user.email'
          title: 'datatable.label.email'
          sort: 'user.email'
        # ,
        #   key: 'user.enabled'
        #   title: 'datatable.label.enabled'
        #   sort: 'user.enabled'
        #   type: 'boolean'
        ,
          key: 'enabled'
          title: 'datatable.label.enabled'
          sort: 'disabled'
          type: 'boolean'
        # ,
        #   key: 'disabled'
        #   title: 'datatable.label.disabled'
        #   sort: 'disabled'
        #   type: 'boolean'
        ,
          key: 'roles.label'
          title: 'datatable.label.roles'
          multiple: 'roles'
          hideInList: true
        ,
          key: 'userContextGroups.label'
          title: 'datatable.label.groups'
          multiple: 'userContextGroups'
          hideInList: true
        ]

      UserBase.getListDefault = UserBase.getList

      UserBase.getList = (query, loadParams) ->
        UserBase.setUrl('user_contexts')
        loadParams['filter[isGroup]'] = 'isGroup,FALSE'

        return $q (resolve, reject) ->
          UserBase.getListDefault(query, loadParams)
            .then (results) ->
              for result in results
                result.enabled = !result.disabled

              resolve results
            , (error) ->
              reject error

      #permission needed to edit/delete an entity
      UserBase.editPermission = 'manage all MundoMosaUserBundle:User entities'

      UserBase.getAuditObjectId = (data) ->
        return data.user.id

      impersonateUserAction =
        [
          icon: 'people'
          text: 'datatable.tooltips.impersonateUser'
          permission: UserBase.impersonateUserPermission
          action: (id) ->
            UserBase.one(id).then(
              (data) ->
                userId = data.user.id
                clientPublicId = mundoConfiguration.oauth.clientId
                username = data.user.username
                isImpersonating = false
                title = "User impersonation"
                message = "You are about to impersonate user '#{username}', do you wish to continue?"

                if $cookies.get 'previous_token'
                  isImpersonating = true
                  message = "You are allready impersonating a user."

                ConfirmBox.showConfirmDialog(title, message)
                .then(->
                  if !isImpersonating
                    Restangular.all '/services/users/impersonation/oauth_v2'
                      .customPOST
                        user: userId
                        clientPublicId: clientPublicId
                      .then (result) ->
                        $cookies.putObject 'previous_token', OAuthToken.getToken(),{'secure' : true}
                        OAuthToken.setToken(result)
                        homeUrl = window.location.protocol + "//" + window.location.host+ "/#/home/"
                        $window.location = homeUrl
                        $window.location.reload()
                )
            )
        ]

      UserBase.addExtraAction(impersonateUserAction)

      #check if a user exist based on email address
      userExists = (email) ->
        UserManager.getFullList()
          .then(
            (userlist) ->
              user = _.filter userlist, (u) -> u.email == email
              user = if user.length? and user.length > 0 then user[0] else undefined
              return user
            (error) -> return undefined
          )

      #check if a user exists in other tennants but not in this one
      userExistsInOtherContext = (email) ->
        userExists(email)
          .then(
            (user) ->
              if user?
                currentTennant = $rootScope.user.activeUserContext.tenant.id
                existsInContext = false
                for context in user.userContexts
                  if context.tenant? and
                    context.tenant.id == currentTennant and
                    !context.deletedAt
                      existsInContext = true

                if existsInContext then return undefined else return user
              else
                return undefined
          )

      UserBase
  ]
